import React from 'react';
import Footer from 'cccisd-footer';
import NavBar from '../../components/NavBar';
import style from './style.css';

const WelcomeLayout = ({ children, className }) => {
    return (
        <div className={style.wrapper}>
            <div className={style.header}>
                <NavBar className={className} />
            </div>

            <div className={style.body}>{children}</div>

            <div className={style.footer}>
                <Footer className={className} />
            </div>
        </div>
    );
};

WelcomeLayout.defaultProps = {
    className: 'container',
};

export default WelcomeLayout;
