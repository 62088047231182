import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link } from 'cccisd-react-router';
import { getHomeRoute } from '../../helpers';
import style from './style.css';

var Fortress = window.cccisd.fortress;

const Welcome = () => {
    const homepageRoute = getHomeRoute();

    return (
        <div className={style.content}>
            <h2>Welcome to Quest</h2>
            {Fortress.auth() ? (
                <div className={style.welcomeBox}>
                    <p>Hello, {Fortress.user.username}</p>
                    {homepageRoute && (
                        <Link to={homepageRoute}>
                            <button type="button" className="btn btn-primary">
                                Go To Home
                            </button>
                        </Link>
                    )}
                </div>
            ) : (
                <LoginForm showPasswordToggle />
            )}
        </div>
    );
};

export default Welcome;
